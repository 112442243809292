<template>
  <div id="home" class="home">
    <a-row>
      <a-col :span="24">
        <div class="banner">
          <img alt="Vue logo" src="@/assets/img/contactBanner.png" style="">
          <div class="banner-des">
            <span>vitual top up expert</span>
            <span>虚拟充值专家 </span>
            <span>寿燚雅·中国视野 </span>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="desc">
    <div class="contact-content ">
      <a-row style="height: 100%;">
        <div class="tab">
          <div class="tab-item">
            <div class="tab-item-left">
              <div class="left-zh">
                <p>与我们取得联系</p>
              </div>
              <div class="left-en">
                <p>CONTACT</p>
                <p>US</p>
              </div>

            </div>
            <div class="tab-item-right">
              <div>
                <span>
                 <phone-outlined rotate=90 :style="{fontSize: '80px'}"/>
                 </span>
                <span>130-1596-2092</span>
              </div>
              <p>（周一至周五 9:00—18:00）</p>
              <div>
                <span>
                  <mail-outlined :style="{ fontSize: '80px' }"/>
                 </span>
                <span>sirfuxiaodong@163.com</span>
              </div>
            </div>
            <div class="contact-foot">
              <div>公司地址</div>
              <div class="address-en">
                <p>COMPANY</p>
                <p>ADDRESS</p>
              </div>
            </div>
          </div>
          <div class="foot-bottom" @click="goMap">
            <img src="@/assets/img/map.png" alt="">
          </div>
        </div>
      </a-row>
    </div>

  </div>
</template>

<script>
import {PhoneOutlined,MailOutlined} from '@ant-design/icons-vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    PhoneOutlined,
    MailOutlined
  },
  name: "News",
  data() {
    return {
      activeKey: "1",
    }
  },
  methods: {
    goMap() {
      location.href = `https://uri.amap.com/marker?position=121.49,31.34&name=寿燚雅网络科技&src=寿燚雅官网`
    }
  },
})
</script>

<style>
.home {
  height: 680px;
  background-color: rgba(104, 110, 130, 1);
  width: 100%;
  min-width: 1280px;

  .banner {
    .banner-des {
      z-index: 999;
      position: absolute;
      height: 100px;
      left: 20%;
      top: 50%;
      display: flex;
      flex-direction: column;

      span {
        color: #fff;
      }

      span:nth-child(1) {
        font-size: 32px;
      }

      span:nth-child(2) {
        margin-top: 20px;
        font-size: 18px;
      }
    }
    img {
      width: 100%;
      height: 100vh;
    }
  }
}

p {
  margin: 0;
}
</style>
<style scoped>
.desc {
  height: 1360px;
  padding: 0 300px;
  min-width: 1280px;
  width: 100%;
  background-color: rgba(28, 31, 43, 1);
  display: flex;
  justify-content: center;

  .contact-content {
    margin-top: 95px;

    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tab-item {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 480px;
        margin-top: 95px;

      }
    }
  }

}


.ant-tabs :deep( .ant-tabs-nav .ant-tabs-tab) {
  color: #000;
}

.ant-tabs :deep( .ant-tabs-nav .ant-tabs-tab-btn) {
  background-image: url(@/assets/desc2.png);
  color: #fff;
  background-color: #1c1f2b;
  width: 160px;
  height: 42px;
  text-align: center;
  line-height: 42px;
}

.ant-tabs :deep( .ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #000;

  background-image: url(@/assets/desc.png);

  width: 160px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-size: cover;
}

.ant-tabs-top :deep( .ant-tabs-nav::before, .ant-tabs-bottom)  > .ant-tabs-nav::before{
  border-bottom: none;
}

.ant-tabs :deep( .ant-tabs-ink-bar) {
  background: none;
}


.tab-item2 .tab-item-left {


  display: flex;
  flex-direction: row;
  margin-top: 120px;
}


.tab-item .tab-item-left {

  height: 58px;
  display: flex;
  flex-direction: row;
  margin-top: 120px;

}

.tab-item .tab-item-right {

  height: 150px;
  display: flex;
  margin-left: 360px;
  margin-top: 44px;
  flex-direction: column;
}

.tab-item .tab-item-right span:nth-child(2) {
  font-size: 72px;
  font-weight: 600;
  margin-left: 40px;
}

.tab-item .tab-item-left .left-en {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 62px;
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;

}

.tab-item2 .tab-item-left .left-en {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  height: 62px;
  font-weight: 600;
  font-size: 16px;

}

.tab-item-left .left-zh p {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.tab-item, .tab-item2 .tab-item-left .left-en {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.left-en p {
  margin: 0;
}


.contact-foot {
  display: flex;
  width: 248px;
  margin-top: 145px;
}

.contact-foot div:nth-child(1) {
  font-size: 40px;
  font-weight: 700;
}

.contact-foot .address-en {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  height: 62px;

}

.contact-foot .address-en p {
  margin: 0;
}

.foot-bottom {
  width: 100%;
  height: 560px;
  margin-top: 40px;
  cursor: pointer;

  img {

  }
}

.ant-carousel :deep(.slick-dots) {
  display: none !important;
}

.tab-item-center {
  margin-left: 160px;

}

.tab-item-center .item-center {
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
}

.tab-item-center .item-center p:nth-child(1) {
  font-size: 20px;
  font-weight: 700;
  width: 80px;
}

.tab-item-center .item-center p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;

  width: 306px;
}

.tab-item-center .item-center p:nth-child(3) {
  font-size: 14px;
  font-weight: 400;

  width: 250px;
}

.tab-item-center .item-center p:nth-child(4) {
  font-size: 14px;
  font-weight: 400;

  width: 160px;
  background-image: url(@/assets/img/more-detail.png);
  background-size: 160px;
  height: 45px;
  line-height: 40px;
  background-repeat: no-repeat;
  text-align: center;


}

</style>
