import {createRouter, createWebHashHistory} from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Home from '@/views/home/home.vue'
import Nav from '@/views/nav/index.vue'
import Footer from '@/views/footer/index.vue'
import About from '@/views/about/index.vue'
import Merchant from '@/views/merchant/index.vue'
import News from '@/views/news/index.vue'
import NewsDetail from '@/views/news/detail.vue'
import Contact from '@/views/contact/index.vue'
import Card from '@/views/card/index.vue'
import Movie from '@/views/movie/index.vue'

const routes = [
    {
        path: '/',
        components: {
            header: Nav,
            default: Home,
            footer: Footer
        }
    },
    {
        path: '/about',
        components: {
            header: Nav,
            default: About,
            footer: Footer
        }
    },
    {
        path: '/card',
        components: {
            header: Nav,
            default: Card,
            footer: Footer
        }
    },
    {
        path: '/movie',
        components: {
            header: Nav,
            default: Movie,
            footer: Footer
        }
    },
    {
        path: '/merchant',
        components: {
            header: Nav,
            default: Merchant,
            footer: Footer
        }
    },
    {
        path: '/contact',
        components: {
            header: Nav,
            default: Contact,
            footer: Footer
        }
    },
    //   {
    //     path: '/',
    //     component: Footer,
    //   },
]
const router = createRouter({
    history: createWebHashHistory(), routes
})

NProgress.configure({easing: 'ease', speed: 500, showSpinner: false});

router.beforeEach((to, from, next) => {
    NProgress.start() // 进度条开始
    next()
})

router.afterEach(() => {
    NProgress.done() // 进度条结束
})

export default router;
