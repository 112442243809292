<template>
    <router-view name="header"></router-view>
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
    <router-view name="footer"></router-view>

</template>

<script>


export default {
  name: 'App',
  components: {
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}
</script>

<style>
/* 隐藏滚动条的样式 */
::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/* 针对Firefox的隐藏滚动条的样式 */
html {
  scrollbar-width: none; /* Firefox */
}
</style>
