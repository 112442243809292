<template>
  <div id="home">
    <a-carousel arrows autoplay class="banner-a">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px;zIndex: 1">
          <left-circle-outlined/>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined/>
        </div>
      </template>
      <div class="banner"><img alt="banner1" src="@/assets/img/movie-banner.jpg"></div>
      <!--      <div class="banner" ><img alt="banner3" src=""></div>-->
    </a-carousel>
    <section class="advantage">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>电影票核心优势</div>
          </div>
          <div class="advantage-item">
            <a-card hoverable style="width: 25%;height: 180px; text-align: center">
              <img alt="example" src="@/assets/img/movie-i.png" style="margin-bottom: 10px"/>
              <a-card-meta title="资源丰富">
                <template #description>覆盖全国99%的院线，约15000+影院</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 180px; text-align: center">
              <img alt="example" src="@/assets/img/price-i.png" style="margin-bottom: 10px"/>
              <a-card-meta title="价格优势">
                <template #description>影票7折起，多渠道竞价出票</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 180px; text-align: center">
              <img alt="example" src="@/assets/img/low-i.png" style="margin-bottom: 10px"/>
              <a-card-meta title="对接门槛低">
                <template #description>无需保证金，无订单出票量要求</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 180px; text-align: center">
              <img alt="example" src="@/assets/img/ke-i.png" style="margin-bottom: 10px"/>
              <a-card-meta title="售后保障">
                <template #description>专业运营客服团队，服务有保障</template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </section>
    <section class="advantage">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>对接要求/收费标准</div>
          </div>
          <div class="advantage-item">
            <div class="item">
              <a-divider style="height: 4px; background-image: linear-gradient(to right, #2589ff, #00adf2);"/>
              <div >
                <a-card title="对接要求" :bordered="false" style="width:360px">
                    <span>
                        <h3>01:个人免费，分佣模式</h3>
                        <p></p>
                    </span>
                  <span>
                        <h3>02:有自己的平台，api授权</h3>
                       <a-checkbox-group :value=options name="checkboxgroup" :options=options></a-checkbox-group >
                    </span>
                  <span>
                        <h3>03:想建自己的平台，但没技术人员</h3>
                       <a-checkbox-group :value=select name="checkboxgroup" :options=select></a-checkbox-group >
                    </span>
                </a-card>
              </div>
            </div>
            <div class="item-center"><img src="@/assets/img/movie-icon.png"  alt=""/></div>
            <div class="item">
              <a-divider style="height: 4px; background-image:linear-gradient(to right, #FD8201, #FDA40C);"/>
              <div >
                <a-card title="收费标准" :bordered="false" style="width: 360px">
                    <span>
                        <h3>01:个人免费</h3>
                        <p>分佣模式免费</p>
                    </span>
                    <span>
                          <h3>02:api授权</h3>
                          <p>999元/年</p>
                      </span>
                    <span>
                          <h3>03:月均出票量达到1千张免年费</h3>
                      </span>
                </a-card>
              </div>
            </div>
          </div>

        </a-col>
      </a-row>
    </section>
    <section class="step">
      <a-row>
        <a-col :span="24">
          <a-list
              :grid="{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3, xxxl: 2 }"
              :data-source="data"
          >
            <template #renderItem="{ item }">
              <a-list-item class="step-item">
                <a-card :title="item.title" style="text-align: center;">
                  <img alt="example" :src="item.img" style="margin-bottom: 10px;"/>
                </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-col>
      </a-row>
    </section>

    <!-- 回到顶部 -->
    <div>
      <a-back-top/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  name: "Movie",
  data() {
    return {
      options: [
        "APP",
        "小程序",
        "H5",
        "PC",
      ],
      select: [
        "外包给我们",
      ],
      data:
        [
          {
            title: '需求沟通',
            img:"/img/process01.png",
          },
          {
            title: '接口测试',
            img:"/img/process02.png",
          },
          {
            title: '协议签订',
            img:"/img/process03.png",
          },
          {
            title: '技术对接',
            img:"/img/process04.png",
          },
          {
            title: '平台联调',
            img:"/img/process05.png",
          },
          {
            title: '部署上线',
            img:"/img/process06.png",
          },
        ]

    }
  },
  methods: {},
})
</script>

<style scoped>
p {
  margin: 0;
}


.ant-carousel :deep(li.slick-active) {
  width: 80px !important;
}

.ant-carousel :deep(li) {
  width: 50px !important;
}

/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 820px;
  line-height: 820px;
  background: #686e82;
  overflow: hidden;
}

.ant-carousel :deep(.slick-dots-bottom) {
  bottom: 60px;
}

.ant-carousel :deep(.custom-slick-arrow) {
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: #fff;
//background-color: rgba(31, 45, 61, 0.11); opacity: 0.8;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-slide) {
  height: calc(100vh - 80px);
}

#home {
  .banner-a {
    min-width: 1280px;

    .banner {
      position: relative;

      .banner-des {
        z-index: 999;
        position: absolute;
        height: 100px;
        left: 20%;
        top: 50%;
        display: flex;
        flex-direction: column;

        span {
          color: #fff;
        }

        span:nth-child(1) {
          font-size: 32px;
        }

        span:nth-child(2) {
          margin-top: 20px;
          font-size: 18px;
        }
      }

      img {
        width: 100%;
        height: 100vh;
      }
    }
  }

  .advantage {
    padding: 0 300px;
    min-width: 1280px;
    margin-top: 1.5rem;

    .advantage-des {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;

      div {
        margin-bottom: 20px;
      }
    }

    .advantage-item {
      display: flex;
      width: 100%;
      height: auto;
      margin-bottom: 50px;
      justify-content: space-between;

      .item {
        width: 45%;

      }
      .item-center{
          display: flex;
          align-items: center;
          img{
            width: 45px;
            height: 45px;
          }
      }
      .item:nth-child(1) {
        margin-top: 20px;
      }
    }

  }
  .step{
    padding: 0 300px;
    .step-item{
      //transition:.5s;

    }
    img:hover {
      transition: transform 300ms;
      /* Rotate div */
      transform:rotate(80deg);
      -ms-transform:rotate(80deg); /* IE 9 */
      -webkit-transform:rotate(80deg); /* Safari and Chrome */
    }
  }
}

.product {
  /* padding: 50px 100px 0px 100px; */
  padding: 0 300px;
  height: 880px;
  background-color: #fff;
  color: #fff;
  min-width: 1280px;
  margin-top: 3.5rem;
}

.product-des {
  height: 8.7rem;
  display: flex;
  flex-direction: column;
}

.product-des div:nth-child(1) {
  position: absolute;
  font-size: 6.25rem;
  line-height: 8.75rem;
  color: #eef1ff;
  font-weight: 500;
}

.product-des div:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 1.7rem;
  font-size: 2.38rem;
  line-height: 3.31rem;
  color: #222c3a;
  font-weight: 600;
}

.product-des div:nth-child(3) {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #0077ef;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}

.tab-item {
  display: flex;
  margin-top: 38px;
}

.ant-tabs :deep(.ant-tabs-nav-list) {
  display: flex;
  justify-content: space-around;
}

.tab-item-children {
  display: flex;
  justify-content: center;
  height: 150px;
  margin: 38px 0;
}

.ant-tabs :deep( .ant-tabs-tab-btn) {
  width: 244px;
  height: 244px;
  line-height: 244px;
  background-color: #f5f9ff;
  color: #000;

}

.ant-tabs :deep( .ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #fff;
  width: 268px;
  background-image: url(@/assets/img/active_bg.png);
  background-size: cover;
  background-color: #fff;
}

.ant-tabs-left > .ant-tabs-content-holder {
  border: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-ink-bar) {
  height: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-tab) {
  padding: 0 !important;
}

.gutter-row {
  margin: 5rem 0 0;
}

.commany-img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 680px; */
  height: 480px;
  margin-left: 40px;
  margin-top: 120px;
}

.commany-img img {
  width: 100%;
  height: 100%;
}

.suggest {
  height: 980px;
  background-color: rgba(28, 33, 54, 1);
  color: #fff;
  padding: 0 300px;
}

.suggest_h {
  display: flex;
  height: 60px;
  align-items: center;
  margin-top: 106px;
  margin-bottom: 0;
}

.product-eg {
  margin-top: 200px;
  text-align: center;
}

.eg {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gutter-example :deep( .ant-row) > div {
  background: transparent;
  border: 0;
}

.hover {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
}

.hover_top {
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
}

.eg:hover .hover_top {

  /* transition: 3s steps(3); */
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.eg:hover .hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.gutter-box {
  padding: 20px;
}

.soft {
  padding: 0 300px;
  background-color: #242c39;
  color: #fff;
}

.soft .ourProductEn {
  color: #263449;
  font-size: 6.25rem;
  line-height: 8.75rem;
  font-weight: 500;
}

.soft .ourProductZh {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 0;
  font-size: 2.38rem;
  line-height: 3.31rem;
  font-weight: 600;
  z-index: 1;
}

.soft .line {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}

.section_tite {
  padding-top: 5rem;
}

.app {
  margin-top: 0.5rem;
}

.app .des p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;

}

.app .desc p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.app .desc p:nth-child(2) {
  padding-top: 0.5rem;
  font-size: 0.875rem;

}

.app .appType {
  margin: 0.5rem 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 31.5rem;
  justify-content: space-between;
}

.app .appType .appTypeItem {
  margin: 1rem 0 0;
  width: 15rem;
  height: 4rem;
  border: 1px solid #fff;
  justify-content: center;
  display: flex;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  font-size: 1.125rem;
}

.divider {
  margin-top: 30px;
  background: rgba(255, 255, 255, 1);
}


</style>
