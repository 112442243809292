<template>
  <div id="home">
    <a-carousel arrows autoplay class="banner-a">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px;zIndex: 1">
          <left-circle-outlined/>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined/>
        </div>
      </template>
      <div class="banner">
        <img alt="banner2" src="@/assets/img/banner1.png">
        <div class="banner-des">
          <span>涵盖各个领域卡券</span>
          <span>会员充值3折起，优惠力度大，秒到账。</span>
          <a-button type="primary" size="large" style="margin-top: 20px">查看详情</a-button>
        </div>
      </div>
      <div class="banner"><img alt="banner1" src="@/assets/img/movie-banner.jpg"></div>
      <!--      <div class="banner" ><img alt="banner3" src=""></div>-->
    </a-carousel>
    <section class="advantage">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>寿燚雅为您引航互联网</div>
            <div>从全品类虚拟商品供货，到专业的运营支持，一站式为企业赋能</div>
          </div>
          <div class="advantage-item">
            <a-card hoverable style="width: 25%;height: 240px; text-align: center">
              <img alt="example" src="@/assets/img/supplyImg1.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
              <a-card-meta title="企业采购" >
                <template #description >员工福利，网络宣传</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 240px; text-align: center">
              <img alt="example" src="@/assets/img/supplyImg2.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
              <a-card-meta title="API接口">
                <template #description>优质渠道，稳定出货</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 240px; text-align: center">

              <img alt="example" src="@/assets/img/supplyImg3.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
              <a-card-meta title="商城合作">
                <template #description>拉新促活，积分兑换</template>
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 240px; text-align: center">

              <img alt="example" src="@/assets/img/supplyImg4.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
              <a-card-meta title="分销加盟">
                <template #description>流量变现，活动运营</template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </section>
    <section class="advantage" style="display: none">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>多场景、多种应用方式</div>
            <div>适用群体: 有自己的私域平台（如网站、App、公众号、小程序等） + 有技术开发能力</div>
          </div>
          <div class="advantage-item">
            <a-card hoverable style="width: 45%;height: 480px; text-align: center">

              <a-card-meta title="H5、小程序分销版" >
                <template #description >
                  <p>免费使用，推广订单 分润50%</p>
<!--                  <p>生成专属推广链接、下单即赚，三级分销模式</p>-->
<!--                  <p> 支持H5、小程序,微信公众号（服务号、订阅号）</p>-->
<!--                  <p>H5链接对接淘客软件、微信，微博、QQ，社区论</p>-->
                </template>
              </a-card-meta>
              <img alt="example" src="@/assets/img/supplyImg1.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
            </a-card>
            <a-card hoverable style="width: 25%;height: 240px; text-align: center">
              <img alt="example" src="@/assets/img/supplyImg2.png" width="100%"
                   height="140px" style="margin-bottom: 10px"/>
              <a-card-meta title="API接口">
                <template #description>优质渠道，稳定出货</template>
              </a-card-meta>
            </a-card>


          </div>
        </a-col>
      </a-row>
    </section>
    <!-- 产品描述 -->
    <!--    <section class="product">-->
    <!--      <a-row>-->
    <!--        <a-col :span="8">-->
    <!--          <div class="product-des">-->
    <!--            <div>BUSSINESS</div>-->
    <!--            <div>服务介绍</div>-->
    <!--            <div></div>-->
    <!--          </div>-->
    <!--        </a-col>-->
    <!--      </a-row>-->
    <!--      <a-row>-->
    <!--        <a-col :span="24" class="gutter-row">-->
    <!--          <a-tabs v-model:activeKey="activeKey" tab-position="left" animated>-->
    <!--            <a-tab-pane key="1" tab="个人用户">-->
    <!--              <div class="tab-item">-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/file.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      文件与证件</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/food.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      食品与饮料</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/digital.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      数码与家电</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--              </div>-->
    <!--              <div class="tab-item">-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/clothes.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      服饰与鞋帽</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/queue.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      帮排队</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="8" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/queue.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      帮排队</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->

    <!--              </div>-->
    <!--            </a-tab-pane>-->
    <!--            <a-tab-pane key="2" tab="企业商户">-->
    <!--              <div class="tab-item">-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/file.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      文件与证件</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/food.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      食品与饮料</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/digital.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      数码与家电</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/digital.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      数码与家电</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--              </div>-->
    <!--              <div class="tab-item">-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/clothes.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      服饰与鞋帽</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/queue.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      帮排队</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/queue.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      帮排队</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--                <a-col :span="6" class="tab-item-children">-->
    <!--                  <div hoverable style="width: 114px;height: 150px;">-->
    <!--                    <div class="" style="width: 114px;height: 114px;"><img alt="example"-->
    <!--                                                                           src="@/assets/img/digital.png"-->
    <!--                                                                           style="width: 100%;height: 100%;"/></div>-->
    <!--                    <p-->
    <!--                        style=" text-align: center; height: 1.75rem;font-size: 1rem;color: #222c3a;font-weight: 400;">-->
    <!--                      数码与家电</p>-->
    <!--                  </div>-->
    <!--                </a-col>-->
    <!--              </div>-->
    <!--            </a-tab-pane>-->
    <!--          </a-tabs>-->

    <!--        </a-col>-->
    <!--      </a-row>-->
    <!--    </section>-->
    <!--    <section class="soft">-->
    <!--      <a-row type="flex" justify="start">-->
    <!--        <a-col :span="4">-->
    <!--          <div class="section_tite">-->
    <!--            <div class="ourProductEn">PRODUCT</div>-->
    <!--            <div class="ourProductZh">我们的产品</div>-->
    <!--            <div class="line"></div>-->
    <!--          </div>-->
    <!--        </a-col>-->

    <!--      </a-row>-->
    <!--      <a-row type="flex" justify="start" style="margin-top: 4rem;">-->
    <!--        <a-col :span="10">-->
    <!--          <div class="app">-->
    <!--            <div class="desc">-->
    <!--              <p>吉兔跑腿APP</p>-->
    <!--              <P>解决所有配送需求</P>-->
    <!--            </div>-->
    <!--            <div class="appType">-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>IOS</span>-->
    <!--              </div>-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>安卓</span>-->
    <!--              </div>-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>微信公众号</span>-->
    <!--              </div>-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>微信公小程序</span>-->
    <!--              </div>-->

    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="app">-->
    <!--            <div class="desc">-->
    <!--              <p>吉兔骑士APP</p>-->
    <!--              <P>空闲时间的赚钱利器</P>-->
    <!--            </div>-->
    <!--            <div class="appType">-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>IOS版本</span>-->
    <!--              </div>-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>安卓版本</span>-->
    <!--              </div>-->

    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="app">-->
    <!--            <div class="desc">-->
    <!--              <p>吉兔骑士家园</p>-->
    <!--              <P>关注吉兔骑士的赚钱技巧和生活分享都在这里</P>-->
    <!--            </div>-->
    <!--            <div class="appType">-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>抖音号</span>-->
    <!--              </div>-->
    <!--              <div class="appTypeItem">-->
    <!--                <span class="iconfont"></span>-->
    <!--                <span>微信公众好</span>-->
    <!--              </div>-->

    <!--            </div>-->
    <!--          </div>-->
    <!--        </a-col>-->

    <!--        <a-col :span="10" :offset="4">-->
    <!--          <div>-->
    <!--            <img src="@/assets/home_phone.png" alt="">-->
    <!--          </div>-->
    <!--        </a-col>-->
    <!--      </a-row>-->
    <!--    </section>-->

    <!-- 回到顶部 -->
    <div>
      <a-back-top/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  name: "Home",
  methods: {},
})
</script>

<style scoped>
p {
  margin: 0;
}


.ant-carousel :deep(li.slick-active) {
  width: 80px !important;
}

.ant-carousel :deep(li) {
  width: 50px !important;
}

/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  background: #686e82;
  overflow: hidden;
}

.ant-carousel :deep(.slick-dots-bottom) {
  bottom: 60px;
}

.ant-carousel :deep(.custom-slick-arrow) {
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: #fff;
//background-color: rgba(31, 45, 61, 0.11); opacity: 0.8;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-slide) {
 height: calc(100vh - 80px);
}

#home{
  .banner-a{
    min-width: 1280px;
    .banner {
      position: relative;
      .banner-des {
        z-index: 999;
        position: absolute;
        height: 100px;
        left: 20%;
        top: 50%;
        display: flex;
        flex-direction: column;
        span {
          color: #fff;
        }
        span:nth-child(1) {
          font-size: 32px;
        }

        span:nth-child(2) {
          margin-top: 20px;
          font-size: 18px;
        }
      }

      img {
        width: 100%;
        height:100vh;
      }
    }
  }
  .advantage {
    padding: 0 300px;
    min-width: 1280px;
    margin-top: 1.5rem;

    .advantage-des{
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      div{
        margin-bottom: 20px;
      }
    }
    .advantage-item {
      display: flex;
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }
  }
}

.product {
  /* padding: 50px 100px 0px 100px; */
  padding: 0 300px;
  height: 880px;
  background-color: #fff;
  color: #fff;
  min-width: 1280px;
  margin-top: 3.5rem;
}

.product-des {
  height: 8.7rem;
  display: flex;
  flex-direction: column;
}

.product-des div:nth-child(1) {
  position: absolute;
  font-size: 6.25rem;
  line-height: 8.75rem;
  color: #eef1ff;
  font-weight: 500;
}

.product-des div:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 1.7rem;
  font-size: 2.38rem;
  line-height: 3.31rem;
  color: #222c3a;
  font-weight: 600;
}

.product-des div:nth-child(3) {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #0077ef;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}

.tab-item {
  display: flex;
  margin-top: 38px;
}

.ant-tabs :deep(.ant-tabs-nav-list) {
  display: flex;
  justify-content: space-around;
}

.tab-item-children {
  display: flex;
  justify-content: center;
  height: 150px;
  margin: 38px 0;
}

.ant-tabs :deep( .ant-tabs-tab-btn ){
  width: 244px;
  height: 244px;
  line-height: 244px;
  background-color: #f5f9ff;
  color: #000;

}

.ant-tabs :deep( .ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #fff;
  width: 268px;
  background-image: url(@/assets/img/active_bg.png);
  background-size: cover;
  background-color: #fff;
}

.ant-tabs-left > .ant-tabs-content-holder {
  border: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-ink-bar) {
  height: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-tab) {
  padding: 0 !important;
}

.gutter-row {
  margin: 5rem 0 0;
}

.commany-img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 680px; */
  height: 480px;
  margin-left: 40px;
  margin-top: 120px;
}

.commany-img img {
  width: 100%;
  height: 100%;
}

.suggest {
  height: 980px;
  background-color: rgba(28, 33, 54, 1);
  color: #fff;
  padding: 0 300px;
}

.suggest_h {
  display: flex;
  height: 60px;
  align-items: center;
  margin-top: 106px;
  margin-bottom: 0;
}

.product-eg {
  margin-top: 200px;
  text-align: center;
}

.eg {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gutter-example :deep( .ant-row) > div {
  background: transparent;
  border: 0;
}

.hover {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
}

.hover_top {
  -webkit-transition: all .3s cubic-bezier(.31, .61, .25, .99);
  transition: all .3s cubic-bezier(.31, .61, .25, .99);
}

.eg:hover .hover_top {

  /* transition: 3s steps(3); */
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.eg:hover .hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.gutter-box {
  padding: 20px;
}

.soft {
  padding: 0 300px;
  background-color: #242c39;
  color: #fff;
}

.soft .ourProductEn {
  color: #263449;
  font-size: 6.25rem;
  line-height: 8.75rem;
  font-weight: 500;
}

.soft .ourProductZh {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 0;
  font-size: 2.38rem;
  line-height: 3.31rem;
  font-weight: 600;
  z-index: 1;
}

.soft .line {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}

.section_tite {
  padding-top: 5rem;
}

.app {
  margin-top: 0.5rem;
}

.app .des p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;

}

.app .desc p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.app .desc p:nth-child(2) {
  padding-top: 0.5rem;
  font-size: 0.875rem;

}

.app .appType {
  margin: 0.5rem 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 31.5rem;
  justify-content: space-between;
}

.app .appType .appTypeItem {
  margin: 1rem 0 0;
  width: 15rem;
  height: 4rem;
  border: 1px solid #fff;
  justify-content: center;
  display: flex;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  font-size: 1.125rem;
}

.divider {
  margin-top: 30px;
  background: rgba(255, 255, 255, 1);
}


</style>
