<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div><img alt="Vue logo" src="" style="width: 100%"></div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <div class="pretty">服务产品</div>
        <div class="services">
            <div class="service-product">
                <div class="service-product-detail">
                    <p>即可达</p>
                    <p>距离2公里内，采用优质专属驻店为主的运力配送服务模式，适合连锁餐饮、高端茶饮、医药等高品质需求客户</p>
                </div>
                <div class="service-product-detail">
                    <p>即可达</p>
                    <p>距离2公里内，采用优质专属驻店为主的运力配送服务模式，适合连锁餐饮、高端茶饮、医药等高品质需求客户</p>
                </div>
            </div>
            <div class="service-product">
                <div class="service-product-detail">
                    <p>即可达</p>
                    <p>距离2公里内，采用优质专属驻店为主的运力配送服务模式，适合连锁餐饮、高端茶饮、医药等高品质需求客户</p>
                </div>
                <div class="service-product-detail">
                    <p>即可达</p>
                    <p>距离2公里内，采用优质专属驻店为主的运力配送服务模式，适合连锁餐饮、高端茶饮、医药等高品质需求客户</p>
                </div>
            </div>
        </div>
    </div>
    <div class="advantages">
        <div class="pretty">服务优势</div>
        <a-tabs v-model:activeKey="activeKey" centered tabBarGutter="160px" style="margin-top: 3.3rem;">
            <a-tab-pane key="1" tab="高效履约">
                <a-row style="height: 100%;">
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                定制化混合动力
                            </div>
                            <div class="tab-item-right"> 以大网运力、优质众包、品牌众包、驻店运力等多层混合运力，前置预测门店情况，精准匹配商家需求，有效应对削峰填谷，全力保障配送时效
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                智慧物流系统
                            </div>
                            <div class="tab-item-right"> 对订单进行智能分派、智能动态定价和全链路实时跟进，准确预判订单趋势，配送节点时效把控</div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                精细化运营
                            </div>
                            <div class="tab-item-right">“总部+城市站”联合运营，搭建严密的服务体系，实现一对一响应，保障大促稳定运营，有效应对削峰填谷</div>
                        </div>
                    </a-col>
                </a-row>
            </a-tab-pane>
            <a-tab-pane key="2" tab="增值服务" force-render>
                <a-row style="height: 100%;">
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                定制装备
                            </div>
                            <div class="tab-item-right"> 品牌定制联名配送装备，提供品牌专属服务，提升品牌形象</div>
                        </div>

                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                系统培训
                            </div>
                            <div class="tab-item-right"> 系统化培训骑士，统一话术与服务标准，为消费者提供有温度的服务</div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                品牌营销
                            </div>
                            <div class="tab-item-right"> 借助众包优势，满足商家大促、节日品牌宣传需求</div>
                        </div>
                    </a-col>
                </a-row>
            </a-tab-pane>
            <a-tab-pane key="3" tab="全链路" force-render>
                <a-row style="height: 100%;">
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                双平台能力
                            </div>
                            <div class="tab-item-right"> 基于集团“即时零售+即时物流”双平台的能力，为大型连锁商超提供“仓、拣、配”全链路数字化的解决方案</div>
                        </div>

                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                吉兔优捡
                            </div>
                            <div class="tab-item-right"> 由众包拣货员为零售门店提供拣货服务，完成全渠道订单拣货、商品打包、订单交付等工作，提升订单拣货效率</div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="tab-item">
                            <div class="tab-item-left">
                                无人配送服务
                            </div>
                            <div class="tab-item-right">
                                依托无人配送开放平台，打造商超即时配送场景下无人配送生态。在遭遇恶劣天气、业务高峰期、夜间配送等运力不稳定情况下，无人配送车可作为补充运力</div>
                        </div>
                    </a-col>
                </a-row>

            </a-tab-pane>
        </a-tabs>
    </div>
    <div class="scene">

        <div class="scene_title">
            业务场景解决方案
        </div>

        <div class="scene_banner">

            <a-carousel arrows>
                <template #prevArrow>
                    <div class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                        <left-circle-outlined />
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow" style="right: 10px">
                        <right-circle-outlined />
                    </div>
                </template>
                <div class="scene_item">
                    <div class="scene_head">
                        <div class="scene_item_icon">
                            <span class="iconfont">3</span>
                        </div>
                        <div class="scene_item_desc">
                            便利店
                        </div>
                    </div>
                    <div class="scene_head">
                        <div class="scene_item_icon">
                            <span class="iconfont">3</span>
                        </div>
                        <div class="scene_item_desc">
                            便利店
                        </div>
                    </div>
                    <div class="scene_item_method">

                    </div>
                </div>
                <div>
                    <h3>2</h3>
                </div>
                <div>
                    <h3>3</h3>
                </div>
                <div>
                    <h3>4</h3>
                </div>
            </a-carousel>
        </div>
    </div>
</template>

<script>
import { SettingOutlined, EditOutlined, EllipsisOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        SettingOutlined,
        EditOutlined,
        EllipsisOutlined,
        LeftCircleOutlined,
        RightCircleOutlined,
    },
    name: "Product",
    data() {
        return {
            activeKey: "1",
        }
    },
    methods: {
        onChange(a, b, c) {
            console.log(a, b, c);
        },
    },
})
</script>

<style>
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {
    height: 680px;
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: #f6f6f6;

}

.services {
    background-color: #fff;
    width: 60%;

}

.pretty {
    padding-top: 5rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #333;
    line-height: 3.5rem;
    text-align: center;
}

.service-product {
    display: flex;
    height: 115;
    padding: 3.3rem 40px;
}

.service-product:nth-child(1) {
    margin-top: 3.3rem;
    padding-bottom: 0;
}

.service-product .service-product-detail {
    width: 50%;
    padding: 0 30px;
}

.advantages {
    height: 680px;
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;

}

.advantages .tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 7.7rem 60px 0;

}

.advantages .tab-item .tab-item-left {
    margin: 1.25rem 0 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    line-height: 1.75rem;
}

.advantages .tab-item .tab-item-right {
    min-height: 5rem;
    font-size: .88rem;
    font-weight: 400;
    color: #888;
    line-height: 1.25rem;
}

.scene {
    padding: 0 300px;
    background-image: url(@/assets/img/merchant_back.jpg);
    color: #fff;
}

.scene .scene_title {
    font-size: 2.5rem;
    padding: 5rem 0 3.5rem;
    font-weight: 400;
    text-align: center;
    line-height: 3.5rem;
    color: #fff;
}

.scene .scene_banner .scene_item {
    display: flex !important;
    justify-content: center;
    margin: 0 35%;
    width: 30% !important;
}

.scene .scene_banner .scene_item .scene_head {
    color: #fff;
    width: 144px;
}

.scene .scene_banner .scene_item .scene_head .scene_item_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    width: 6.88rem !important;
    height: 6.88rem !important;
    background: hsla(0, 0%, 62.7%, .17);
    border-radius: 3.44rem;
}

.scene .scene_banner .scene_item .scene_head .scene_item_desc {
    margin: 0 auto;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 600;
    width: 6.88rem !important;
}

.ant-tabs:deep(.ant-tabs-nav .ant-tabs-tab-btn) {

    color: #888;
    font-size: 1.25rem;
    font-weight: 600;

}

.ant-tabs:deep(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #07e;
}

.ant-tabs-top:deep(.ant-tabs-nav::before,
.ant-tabs-bottom)>.ant-tabs-nav::before {
    border-bottom: none;
}

.ant-carousel:deep(.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;

    opacity: 0.8;
}

.ant-carousel:deep(.custom-slick-arrow:before ){
    display: none;
}

.ant-carousel:deep(.custom-slick-arrow:hover) {
    opacity: 1;
}
</style>
