<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
                <div><img alt="Vue logo" src="" style="width: 100%"></div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <div class="news-content ">
            <a-row style="height: 100%;">
                <a-col :span="24">
                    <div class="row">
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                                

                            </div>
                        </div>
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                                

                            </div>
                        </div>
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                                

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                                

                            </div>
                        </div>
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                            </div>
                        </div>
                        <div class="news">
                            <div class="news-head">
                                <div class="left">
                                    <p>28</p>
                                    <p>2023/03</p>
                                </div>
                                <div class="right">
                                    <arrow-right-outlined :style="{fontSize: '24px'}" />
                                </div>
                            </div>
                            <div class="news-desc">
                                <p>我是产品，我是产品我是产品</p>
                                <p>我是介绍，我是介绍我是介绍我是介绍我是介绍我是介绍我是介绍，我是介绍我是介绍。</p>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>

    </div>
</template>
 
<script>
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        ArrowRightOutlined,
    },
    name: "News",
    data() {
        return {

        }
    },
    methods: {
        onChange(a, b, c) {
            console.log(a, b, c);
        },
    },
})
</script>

<style>
.home {
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc {
    height: 990px;
    padding: 0 300px;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    justify-content: center;
}

.news {
    width: 100%;
    height: 298px;
    background-color: rgba(41, 41, 41, 1);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    margin-right: 40px;
    border: 1px solid #161922;
    color: rgba(255, 255, 255, 1);
    padding: 0 55px;
}

.news-content {
    margin-top: 110px;
}

.news:hover {
    background-color: rgba(0, 0, 0, 1);
   
}

.news-head {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 330px;
    height: 60px;
    margin-top: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.news-head .left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.news-head .left p:nth-child(1){
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.news-head .left p:nth-child(2){
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.news-head .right{
    display: flex;

    width: 50%;
    align-items: center; 
    justify-content: end;
}
.news-desc p:nth-child(1) {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.news-desc p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto;
}

.row{
    display: flex;
}
</style>