<template>
    <div id="footer">
      <footer>
        <a-row class="desc">
                <a-col :span="3">
                    <p class="h3"><router-link to="/card">权益卡</router-link></p>
                </a-col>
                <a-col :span="3">
                    <p class="h3" >电影票</p>
                </a-col>
                <a-col :span="3">
                    <p class="h3" >定制开发</p>
                </a-col>
                <a-col :span="3">
                    <p class="h3" >联系我们</p>
                </a-col>
                <a-col :span="6" class="qrcode">
                    <div class="wechat white">
                        <img src="@/assets/qrcode.jpg" alt="">
                        <p>官方公众号</p>
                    </div>
                    <div class="wechat white">
                      <img src="@/assets/img/customer.png" alt="">
                      <p>微信客服</p>
                    </div>
                </a-col>
                <a-col :span="6" class="white">
                    <span style="display: flex">服务热线： <p style="font-size: 16px;font-weight: 300;">17513146328</p></span>

                    <p>地址：上海市宝山区长逸路188号1幢10层</p>
                </a-col>
        </a-row>

        <a-divider class="divider" />
        <a-row>
          <a-col :span="24">
            <div class="record">
              <div class="left">
                ©{{ `${year} ` }} 上海寿燚雅网络科技有限公司 &nbsp;&nbsp;备案号：
                <a style="cursor: pointer; color: #fff;" href="https://beian.miit.gov.cn" target="_blank">沪ICP备2023005932号-1</a>
              </div>
              </div>
          </a-col>
        </a-row>
      </footer>
    </div>
  </template>

  <script>
  export default {
    name: "Footer",
    data() {
      return {
        year: new Date().getFullYear(),
      }
    },
}
</script>

<style scoped>
#footer {
    width: 100%;
    min-width: 1280px;
  color:rgba(214, 214, 214, 1);
  background-color: #1c2136;

  /* padding-bottom: .5rem; */
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
  padding-left: 300px;
    padding-right: 300px;
}
.desc{
    padding-top: 100px;
    height: 300px;
    .qrcode{
      display: flex;
    }
}
.h3{
    font-size: 16px;
    color:#fff;
}
.record{
    height: 60px;
    display: flex;
    align-items: center;
}
.white{
    color: #fff;
}
.divider{
    margin: 0;
    background: rgba(170, 170, 170, 1);
}
.wechat {
    width: 75px;
    height: 75px;
    text-align: center;
    margin-left: 15px;
}
.wechat img{
    width: 100%;
    height: 100%;
}
</style>

