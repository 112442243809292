<template>
  <div id="home">
    <a-carousel arrows autoplay class="banner-a">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px;zIndex: 1">
          <left-circle-outlined/>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined/>
        </div>
      </template>
      <div class="banner">
        <img alt="banner2" src="@/assets/img/banner1.png">
        <div class="banner-des">
          <span>涵盖各个领域卡券</span>
          <span>会员充值3折起，优惠力度大，秒到账。</span>
          <a-button type="primary" size="large" style="margin-top: 20px">查看详情</a-button>
        </div>
      </div>
    </a-carousel>
    <section class="advantage">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>推荐产品</div>
          </div>
          <div class="advantage-item">
            <a-card hoverable style="width: 25%;height: 200px; text-align: center">
              <img alt="example" src="/img/recommendIcon1.png" style="margin-bottom: 10px"/>
              <a-card-meta title="视频会员">
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 200px; text-align: center">
              <img alt="example" src="/img/recommendIcon2.png" style="margin-bottom: 10px"/>
              <a-card-meta title="音频会员">
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 200px; text-align: center">
              <img alt="example" src="/img/recommendIcon6.png" style="margin-bottom: 10px"/>
              <a-card-meta title="话费油卡">
              </a-card-meta>
            </a-card>
            <a-card hoverable style="width: 25%;height: 200px; text-align: center">
              <img alt="example" src="/img/recommendIcon8.png" style="margin-bottom: 10px"/>
              <a-card-meta title="生活卡券">
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </section>
    <section class="service">
      <a-row>
        <a-col :span="24">
          <div class="advantage-des">
            <div>服务范围</div>
          </div>
          <div class="list">
            <a-list item-layout="horizontal" :data-source="list" :grid="{ gutter: 24, column: 2 }" size="large">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta
                      :description=item.des
                  >
                    <template #title>
                      <a href="https://www.antdv.com/">{{ item.title }}</a>
                    </template>
                    <template #avatar>
                      <a-avatar :src="item.img" style="height:45px;width: 45px;" />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </div>
        </a-col>
      </a-row>
    </section>


    <!-- 回到顶部 -->
    <div>
      <a-back-top/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  name: "Card",
  data() {
    return {
      list: [
        {
          title: '充值接口',
          img:"/img/range1.png",
          des:"覆盖文娱、游戏、生活服务、通讯等八大品类",
        },
        {
          title: '虚拟商城',
          img:"/img/range2.png",
          des:"商品、UI均可定制、运营、客户全托管",
        },
        {
          title: '批充批采',
          img:"/img/range3.png",
          des:"虚拟商城批量充值、批量采购",
        },
        {
          title: '礼品卡',
          img:"/img/range4.png",
          des:"灵活定制面值及玩法，助力营销赠礼、员工福利发放",
        }, {
          title: '企业福利',
          img:"/img/range5.png",
          des:"为企业提供福利商城、员工关怀、积分发放等员工福利解决方案",
        },
        {
          title: '兑换卡券',
          img:"/img/range6.png",
          des:"覆盖全品类虚拟商品，灵活定制运营玩法",
        },
      ]
    }
  },
  methods: {},
})
</script>

<style scoped>
p {
  margin: 0;
}


.ant-carousel :deep(li.slick-active) {
  width: 80px !important;
}

.ant-carousel :deep(li) {
  width: 50px !important;
}

/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  background: #686e82;
  overflow: hidden;
}

.ant-carousel :deep(.slick-dots-bottom) {
  bottom: 60px;
}

.ant-carousel :deep(.custom-slick-arrow) {
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: #fff;
//background-color: rgba(31, 45, 61, 0.11); opacity: 0.8;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-slide) {
  height: calc(100vh - 80px);
}

#home {
  .banner-a {
    min-width: 1280px;

    .banner {
      position: relative;

      .banner-des {
        z-index: 999;
        position: absolute;
        height: 100px;
        left: 20%;
        top: 50%;
        display: flex;
        flex-direction: column;

        span {
          color: #fff;
        }

        span:nth-child(1) {
          font-size: 32px;
        }

        span:nth-child(2) {
          margin-top: 20px;
          font-size: 18px;
        }
      }

      img {
        width: 100%;
        height: 100vh;
      }
    }
  }

  .advantage {
    padding: 0 300px;
    min-width: 1280px;
    margin-top: 1.5rem;

    .advantage-des {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;

      div {
        margin-bottom: 20px;
      }
    }

    .advantage-item {
      display: flex;
      width: 100%;
      height: auto;
      margin-bottom: 50px;
      justify-content: space-between;
    }

  }

  .service {
      background-color: #fafafc;
      .advantage-des{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
      }
      .list{
        padding-top: 40px;
        margin: 0 300px;
      }
  }
}


.product-des div:nth-child(1) {
  position: absolute;
  font-size: 6.25rem;
  line-height: 8.75rem;
  color: #eef1ff;
  font-weight: 500;
}

.product-des div:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 1.7rem;
  font-size: 2.38rem;
  line-height: 3.31rem;
  color: #222c3a;
  font-weight: 600;
}

.product-des div:nth-child(3) {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #0077ef;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}


.ant-tabs :deep(.ant-tabs-nav-list) {
  display: flex;
  justify-content: space-around;
}


.ant-tabs :deep( .ant-tabs-tab-btn) {
  width: 244px;
  height: 244px;
  line-height: 244px;
  background-color: #f5f9ff;
  color: #000;

}

.ant-tabs :deep( .ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #fff;
  width: 268px;
  background-image: url(@/assets/img/active_bg.png);
  background-size: cover;
  background-color: #fff;
}

.ant-tabs-left > .ant-tabs-content-holder {
  border: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-ink-bar) {
  height: 0 !important;
}

.ant-tabs-left :deep(.ant-tabs-tab) {
  padding: 0 !important;
}

.gutter-row {
  margin: 5rem 0 0;
}

.commany-img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 680px; */
  height: 480px;
  margin-left: 40px;
  margin-top: 120px;
}

.commany-img img {
  width: 100%;
  height: 100%;
}


.gutter-example :deep( .ant-row)  > div{
  background: transparent;
  border: 0;
}


.eg:hover .hover_top {

  /* transition: 3s steps(3); */
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.eg:hover .hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}


.soft .ourProductEn {
  color: #263449;
  font-size: 6.25rem;
  line-height: 8.75rem;
  font-weight: 500;
}

.soft .ourProductZh {
  color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 0;
  font-size: 2.38rem;
  line-height: 3.31rem;
  font-weight: 600;
  z-index: 1;
}

.soft .line {
  height: 9px;
  width: 88px;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 99;
  bottom: 10px;
}


.app .des p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;

}

.app .desc p:nth-child(1) {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.app .desc p:nth-child(2) {
  padding-top: 0.5rem;
  font-size: 0.875rem;

}

.app .appType {
  margin: 0.5rem 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 31.5rem;
  justify-content: space-between;
}

.app .appType .appTypeItem {
  margin: 1rem 0 0;
  width: 15rem;
  height: 4rem;
  border: 1px solid #fff;
  justify-content: center;
  display: flex;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  font-size: 1.125rem;
}


</style>
