<template>
    <div id="home" class="home">
        <a-row>
            <a-col :span="24">
            <div><img alt="Vue logo" src="" style="width: 100%"></div>
            </a-col>
        </a-row>
    </div>
    <div class="desc">
        <a-row style="height: 100%;">
            <a-col :span="24">
                <a-tabs v-model:activeKey="activeKey" centered tabBarGutter="160px">
                    <a-tab-pane key="1" tab="公司简介" >
                        <div class="tab-item">
                            <div class="tab-item-left">我是文字，我是文字我是文字我是文字我是文字。我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字。我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字。</div>
                            <div class="tab-item-right"><img src="../../assets/logo2.png" alt=""></div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="公司历史" force-render>
                        <div class="tab-item">

                            <a-carousel arrows dots=false  class="swiper" >
                                <template #prevArrow>
                                    <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                                        <left-circle-outlined />
                                    </div>
                                </template>
                                <template #nextArrow>
                                    <div class="custom-slick-arrow" style="right: 10px">
                                        <right-circle-outlined />
                                    </div>
                                </template>

                                    <div class="history-center"  v-for="(item,index) in historys" :key="item">
                                        <div class="year_before">
                                            <p>{{ index>0 ? historys[index-1].date:"" }}</p>
                                        </div>
                                        <div class="history">
                                            <div class="history-title">{{ item.date }}</div>

                                             <div class="history-detail">{{ item.des }}</div>
                                        </div>

                                        <div class="year_after">
                                            <p>{{  index <historys.length-1 ?historys[index+1].date:"" }}</p>
                                       </div>

                                    </div>


                            </a-carousel>

                        </div>
                        <div ><img src="@/assets/time-line.png" alt="" style="width:  100%;position: relative;bottom: 250px;z-index: 9999;"></div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="荣誉资质">Content of Tab Pane 3</a-tab-pane>
                </a-tabs>
            </a-col>
        </a-row>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
export default defineComponent({
    components: {
        LeftCircleOutlined,
        RightCircleOutlined,
    },
    name: "About",
    data(){
        return {
            activeKey:"2",
            historys:[
                {
                    date:"2016",
                    des:"我是2015.我是文字，我是文字我是文字我是文字我是文字。我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字。"
                },
                {
                    date:"2017",
                    des:"我是文字，我是文字我是文字我是文字我是文字。我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字。"
                },
                {
                    date:"2018",
                    des:"我是2017.我是文字，我是文字我是文字我是文字我是文字。我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字，我是文字我是文字我是文字我是文字我是文字我是文字我是文字我是文字。"
                }
            ],
        }
    },
    methods: {
        onChange(a, b, c) {
            console.log(a, b, c);
        },
    },
})
</script>

<style>
.home{
    height: 680px;
    background-color: rgba(104, 110, 130, 1);
    width: 100%;
    min-width: 1280px;
}
</style>
<style scoped>
.desc{
    height: 990px;
    padding: 0 300px ;
    min-width: 1280px;
    width: 100%;
    background-color: rgba(28, 31, 43, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-tabs :deep(.ant-tabs-nav).ant-tabs-tab {
    color: #000;
}
.ant-tabs :deep(.ant-tabs-nav  .ant-tabs-tab-btn){
    background-image: url(@/assets/desc2.png) ;
    color: #fff;
    background-color: #1c1f2b;
    width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
}

.ant-tabs :deep(.ant-tabs-tab-active .ant-tabs-tab-btn){
    color: #000;

   background-image: url(@/assets/desc.png) ;

   width: 160px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background-size: cover;
}
 .ant-tabs-top :deep(  .ant-tabs-nav::before,.ant-tabs-bottom > .ant-tabs-nav::before) {
    border-bottom: none;
}
.ant-tabs :deep( .ant-tabs-ink-bar){
    background: none;
}
.tab-item{
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1300px;
    height: 480px;
    margin-top: 95px;

}
.tab-item .tab-item-left,.tab-item-right{
    width: 50%;
    height: 100%;
}
.tab-item .tab-item-left{
    padding: 40px 40px 0  0 ;

}
.tab-item-right img{
    width: 100%;
    height: 100%;
}
.swiper{
    width: 100%;
    height: 100%;
}
.swiper .history-center{
    display: flex !important;
    width: 100%;
    justify-content: center;
}
.history{
    width: 524px !important;
    color: #fff;
    text-align: center;
    height: 460px;

}
.history .history-title{
    font-size: 7.8125vw;
    line-height: 13.5417vw;

}
.history .history-detail{
width: 100%;

margin-top: 85px;
}
.year_after{
    width: 30px;
    height: 30px;
    color: #fff;
    position: relative;
    top: 180px;
    left: 95px;
}
.year_before{
    width: 30px;
    height: 30px;
    color: #fff;
    position: relative;
    top: 180px;
    right: 98px;
}

.ant-carousel .slick-prev, :deep(.ant-carousel .slick-next) {

top: 35%;


}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.8;
}
.ant-carousel :deep(.slick-dots){
    display: none !important;
}
</style>
