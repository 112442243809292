<template>
    <div id="header">
        <header>
            <a-row>
                <a-col :span="6">
                    <div class="logo">
                      <img alt="logo" src="@/assets/logo.png">
                      <img alt="logo2" src="@/assets/logoFont.png">
                    </div>
                </a-col>
                <a-col :span="18" class="nav">
                    <a-space size="large" class="nav-route">
                        <!-- 使用 router-link 组件来导航. -->
                        <!-- 通过传入 `to` 属性指定链接. -->
                        <!-- <router-link> 默认会被渲染成一个 `<a>` 标签 -->

                        <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="horizontal"
                            @click="menuClick">
                            <a-menu-item key="home">
                                <a href="#" rel="noopener noreferrer">
                                    首页
                                </a>
                            </a-menu-item>

                            <a-menu-item key="card">
                              <a href="#/card" rel="noopener noreferrer">
                                权益卡
                              </a>
                            </a-menu-item>
                            <a-menu-item key="movie">
                              <a href="#/movie" rel="noopener noreferrer">
                                电影票
                              </a>
                            </a-menu-item>
<!--                            <a-sub-menu>-->
<!--                                <template #title>-->
<!--                                    <a href="#about">定制开发</a>-->
<!--                                </template>-->
<!--                            </a-sub-menu>-->
                            <a-sub-menu key="contact">
                                <template #title><a href="#contact"> 联系我们</a></template>
                            </a-sub-menu>

                        </a-menu>

                    </a-space>

                </a-col>
            </a-row>
        </header>
    </div>
</template>
 
<script>

import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';

export default {
    name: "Nav",
    components: {
        MailOutlined,
        AppstoreOutlined,
        SettingOutlined,
    },
    data() {
        return {
            selectedKeys: ['2'],
            openKeys: ['1'],
        }
    },
    methods: {
        // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
        menuClick({ item, key, keyPath }) {
            console.log(key)
            // 获取到当前的key,并且跳转
            this.$router.push({
                path: key
            })
        }
    },
    setup() {

    },
}
</script>
 
<style scoped>
a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #333;
    /* 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写 */
}

#header {
    height: 80px;
    width: 100%;
    min-width: 1280px;
    color: #fff;
    /* background-image: linear-gradient(#164e73, #01aee6); */
    background-color: #fff;
    box-shadow: 0 0.13rem 0.63rem 0 rgb(0 0 0 / 5%);
    padding-top: 1rem;
    padding-bottom: .5rem;
    padding-left: 300px;
    padding-right: 300px;
    position: fixed;
    z-index: 999;
}

.logo {
    height: 63px;
    width: 246px;
    overflow: hidden;

    display: flex;
    align-items: center;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: end;

}

.nav-route {
    font-size: 1rem !important;
    font-weight: 400;
}

.ant-menu-horizontal {
    border-bottom: none;
}</style>
